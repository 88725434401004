import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import '../css/Login.css';
import { ReactComponent as IconCheckEmail } from '../assets/icons/CheckEmail.svg';
import Colors from '../theme/Colors';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';
import {
  passwordReset as passwordResetSchema,
  email as emailSchema,
} from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import defaultGet from '../utils/defaultGet';
import Button from '../components/Button';
import Modal from '../components/Modal';
import AnimatedTextInput from '../components/AnimatedTextInput';
import logo from '../assets/images/logo.svg';

type TFormErrors = {
  email?: string;
  password?: string;
};

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const [email, setEmail] = useFormFieldValidation(
    emailSchema,
    'email',
    '',
    submitted,
    errors,
    setErrors,
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSubmitted(true);
    setErrors({});

    if (!formValidation(passwordResetSchema, { email }, setErrors)) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.authReset({
      email,
      onSuccess: () => {
        setLoading(false);
        setVisible(true);
      },
      onError: (error: Error) => {
        const errorCode = defaultGet(error, 'code', 'err_unknown');
        const text = t([`errorCodes.forgotPassword.${errorCode}`, 'errorCodes.err_unknown']);

        dispatch(UIActions.addNotification({ text, type: 'error' }));

        setLoading(false);
      },
    }));
  };

  return (
    <article className="page forgot-password unauth">
      <section className="content">
        <section className="image-container">
          <header>
            <img src={logo} alt="Owise" />
          </header>
        </section>
        <form onSubmit={handleSubmit}>
          <h1 className="heading-large">{t('password-reset-title')}</h1>
          <p className="body">{t('password-reset-description')}</p>
          <AnimatedTextInput
            value={email}
            labelText={t('email')}
            onChange={(event) => setEmail(event.currentTarget.value)}
            inputType="email"
            errorText={errors.email ? t(errors.email) : ''}
          />
          <Button buttonType="submit" disabled={loading} size="large">
            {
              loading
                ? <Loader type="TailSpin" color={Colors.white} height={20} width={20} />
                : t('send')
            }
          </Button>
          <Link to="/login" className="button accent">{t('login')}</Link>
        </form>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('done'),
            onClick: () => {
              setVisible(false);
              history.push('/');
            },
            primary: true,
          },
        ]}
        className="mail-sent"
      >
        <h1 className="heading">{t('password-reset-mail-sent-title')}</h1>
        <h1 className="heading accent">{email}</h1>
        <p className="body">{t('password-reset-mail-sent-description')}</p>
        <p className="body">{t('mail-sent-spam')}</p>
        <div className="icon-container">
          <IconCheckEmail width={132} height={132} fill={Colors.secondary} />
        </div>
      </Modal>
    </article>
  );
};

export default ForgotPassword;
