import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import '../css/Login.css';
import map from 'lodash/map';
import Dropdown from 'react-dropdown';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';
import {
  login as loginSchema,
  email as emailSchema,
  passwordOldRules as passwordSchema,
  options as optionsSchema,
} from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import Button from '../components/Button';
import AnimatedTextInput from '../components/AnimatedTextInput';
import defaultGet from '../utils/defaultGet';
import Colors from '../theme/Colors';
import logo from '../assets/images/logo.svg';
import localConfig from '../config';

type TFormErrors = {
  email?: string;
  password?: string;
};

const Login = () => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();

  const [email, setEmail] = useFormFieldValidation(
    emailSchema,
    'email',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [password, setPassword] = useFormFieldValidation(
    passwordSchema,
    'password',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [environment, setEnvironment] = useFormFieldValidation(
    optionsSchema(false, localConfig.environments),
    'environment',
    'prod',
    submitted,
    errors,
    setErrors,
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSubmitted(true);
    setErrors({});

    if (!formValidation(loginSchema, { email, password }, setErrors)) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.authLogin({
      email,
      password,
      environment,
      onSuccess: ({ redirectUrl }: { redirectUrl: string }) => history.push(redirectUrl),
      onError: (error: Error) => {
        const code = defaultGet(error, 'code', 'err_unknown');
        const text = t([`errorCodes.login.${code}`, 'errorCodes.err_unknown']);

        dispatch(UIActions.addNotification({ text, type: 'error' }));

        setLoading(false);
      },
    }));
  };

  const environments = map(localConfig.environments, (option) => ({
    value: option,
    label: t(`environments.${option}`),
  }));

  return (
    <article className="page login unauth">
      <section className="content">
        <section className="image-container">
          <header>
            <img src={logo} alt="Owise" />
          </header>
        </section>
        <form onSubmit={handleSubmit}>
          <h1 className="heading-large">{t('login-title')}</h1>
          <AnimatedTextInput
            value={email}
            labelText={t('email')}
            onChange={(event) => setEmail(event.currentTarget.value)}
            inputType="email"
            errorText={t(errors.email as string)}
          />
          <AnimatedTextInput
            value={password}
            labelText={t('password')}
            onChange={(event) => setPassword(event.currentTarget.value)}
            inputType="password"
            errorText={t(errors.password as string)}
          />
          {localConfig.showEnvironments ? (
            <Dropdown
              options={environments}
              onChange={(option) => setEnvironment(option.value)}
              value={environment}
              placeholder={t('environment')}
            />
          ) : null}
          <Button buttonType="submit" disabled={loading} size="large">
            {
              loading
                ? <Loader type="TailSpin" color={Colors.white} height={20} width={20} />
                : t('login')
            }
          </Button>
          <Link to="/forgot-password" className="button accent">{t('forgot-password')}</Link>
          <Link to="/signup" className="no-account">
            <p className="button">{t('no-account')}</p>
            <p className="button accent">{t('sign-up')}</p>
          </Link>
        </form>
      </section>
    </article>
  );
};

export default Login;
